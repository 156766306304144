#skills{
    margin-top: -10%;
}

.contenedor{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.iconos{
    display: flex;
    flex-direction: column;
    margin: 3%;
    text-align: center;
}

.iconos:hover{
   transform: scale(1.20);
}

.ico{
    font-size: 50px;
}

#js{
    color:rgb(239, 239, 3);
}

.aprendiendo{
    margin:40px;
    margin-top:70px;
    font-size: 30px;
}

@media screen and (max-width: 200px) {
   .contenedor {
      flex-direction: column;
      width: 100%;
    } 

    .iconos {
      margin: 20%;
    }

    .ico {
        font-size: 30px;
    }
}


